@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.video-js {
  width: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  font-size: 13px !important;
}

.vjs-tribe.video-js {
  color: #fff;
}

.vjs-tribe .vjs-big-play-button {
  border-color: #ff0000;
}

.vjs-tribe .vjs-volume-level,
.vjs-tribe .vjs-play-progress,
.vjs-tribe .vjs-slider-bar {
  background: #307fe2 !important;
}

.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.video-js .vjs-current-time {
  display: block !important;
}

.bg-dots-white {
  background: linear-gradient(90deg, white 17px, transparent 1%) center,
    linear-gradient(white 17px, transparent 1%) center, #e5e7eb;
  background-size: 22px 22px;
}

.bg-dots-dark {
  background: linear-gradient(90deg, #21282f 17px, transparent 1%) center,
    linear-gradient(#21282f 17px, transparent 1%) center, rgba(229, 231, 235, 0.2);
  background-size: 22px 22px;
}

.bg-dots-light {
  background: linear-gradient(90deg, #f9fafb 7.98px, transparent 1%) center,
    linear-gradient(#f9fafb 7.98px, transparent 1%) center, #e5e7eb;
  background-size: 10.33px 10.33px;
}

.text-tiny {
  font-size: 0.6875rem;
}

@media (min-width: 1300px) {
  .xl-block {
    display: block !important;
  }
}

.video-info p {
  line-height: 1.5;
  margin-bottom: 1.875rem;
}

@media (max-width: 640px) {
  body .contentpage {
    /*margin-top: calc(56% + 50px) !important;*/
  }

  body .frontpage {
    /* margin-top: 50px !important; */
  }
}

.contentpage .preview-container {
  height: auto;
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  display: flex;
  flex-direction: column;
}

.contentpage .preview-container-chat-closed {
}

@media (min-width: 992px) {
  .contentpage .preview-container {
    /* height: calc(100vh - 64px); */
    flex-direction: row;
  }

  .contentpage .preview-container-chat-closed {
    flex-direction: column;
  }
}

.contentpage .video-container {
  width: 100%;
  margin-top: 56px;
  position: fixed;
}

@media (min-width: 992px) {
  .contentpage .video-container {
    width: 75%;
    margin-top: 0px;
    position: relative;
  }
}

.contentpage .chat-container {
  /* width: 100%; */
  background: #161616;
  /* margin-top: calc(56.25% + 55px); */
}

@media (min-width: 992px) {
  .contentpage .chat-container {
    /* width: 25%; */
    margin-top: 0;
  }
}

.contentpage .video-container-chat-disabled {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .video-embed,
  .video-embed-resi {
    /* padding-bottom: 56.25%; */
  }

  .video-embed-chat-disabled,
  .video-embed-resi-chat-disabled {
    /* padding-bottom: 56.25%; */
  }
}

@media (min-width: 992px) {
  .video-embed,
  .video-embed-resi {
    position: relative;
    overflow: hidden;
    /* padding-bottom: calc(100vh - 320px); */
  }

  .video-embed-chat-disabled,
  .video-embed-resi-chat-disabled {
    position: relative;
    /*overflow: hidden;*/
    /*padding-bottom: 49%;*/
    /* padding-bottom: calc(100vh - 220px); */
  }
}

@media (min-width: 992px) {
  .video-embed,
  .video-embed-resi {
    position: relative;
    overflow: hidden;
    /* padding-bottom: calc(100vh - 320px); */
  }

  .video-embed-chat-disabled,
  .video-embed-resi-chat-disabled {
    position: relative;
    /*overflow: hidden;*/
    /*padding-bottom: 49%;*/
    /* padding-bottom: calc(100vh - 220px); */
  }

  .video-embed-container-height {
    /* max-height: calc(100vh - 65px); */
  }

  .video-embed-chat-disabled.no-ads,
  .video-embed-resi-chat-disabled.no-ads {
    /* padding-bottom: calc(100vh - 65px); */
  }

  .video-embed.no-ads,
  .video-embed-resi.no-ads {
    /* padding-bottom: calc(100vh - 65px); */
  }
}

@media (min-width: 992px) {
  .video-js.vjs-16-9 {
    padding-top: 48% !important;
  }
}

/*.video-embed-resi {
  padding-bottom: 56.25%;
}

.video-embed-resi-chat-disabled {
  padding-bottom: 56.25%;
}

@media (min-width: 992px) {
  .video-embed-resi {
    padding-bottom: 42%;
  }

  .video-embed-resi-chat-disabled {
    padding-bottom: 49%;
  }
}*/

/* .tribe-nav {
  position: fixed;
}

@media (min-width: 992px) {
  .tribe-nav {
    position: relative;
  }
} */

#chatMessages {
  height: calc(100vh - 150px);
  min-height: 400px;
  overflow: auto;
}

@media (max-width: 768px) {
  #chatMessages {
    height: 50vh;
    min-height: none;
  }

  .video-embed-container-height {
    max-height: none;
  }
}

/*.contentchatonly #chatMessages {
   height: 100%; 
  max-height: none;
} */

.bg-nav {
  background-color: #191f27;
}

.button {
  background-color: #307fe2;
}

.button:hover {
  background-color: #1766c9;
}

.video-tag {
  background-color: #e4fffa;
  color: #0b967c;
}

.text-pale-sky {
  color: #6b7280;
}

.contentDescription.prose,
.contentDescription.prose * {
  color: white;
}

.prose h1,
.prose h2,
.prose h3 {
  color: inherit;
}

/* transitions */

.modal-transition-enter {
}

.modal-transition-enter-active {
}

.modal-transition-exit {
}

.modal-transition-exit-active {
}

.modal-transition-appear {
}

.modal-transition-appear-active {
}

.fade-enter {
  opacity: 0.5;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

/* override lib */
div.text-editor {
  font-family: inherit;
}

.content {
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
}

.close-chat {
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
}

.video-container-inner:hover .content {
  opacity: 1;
  pointer-events: all;
}

.video-container-inner:hover .close-chat {
  opacity: 1;
  pointer-events: all;
}

.content .video-title {
  font-size: 20px;
  font-weight: 600;
}

.content .video-author {
  font-size: 15px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .content .video-title {
    font-size: 52px;
    line-height: 1;
  }

  .content .video-author {
    font-size: 20px;
  }
}

.secondary-collection li {
  flex-basis: 300;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

@media (min-width: 992px) {
  .secondary-collection li {
    flex-basis: 31.5%;
  }
}

.col-1 {
  width: 100%;
}

.col-2 {
  width: 100%;
}

@media (min-width: 992px) {
  .col-1 {
    width: 75%;
  }

  .col-2 {
    width: 25%;
  }
}

.secondary-collection-float {
  margin: 0 auto;
  max-width: 350px;
  overflow: auto;
}

.secondary-collection-float li {
  width: 100%;
  margin-right: 0;
  float: none;
}

@media (min-width: 992px) {
  .secondary-collection-float {
    margin: 0;
    max-width: none;
    overflow: auto;
  }

  .secondary-collection-float li {
    width: 270px;
    margin-right: 25px;
    float: left;
  }
}

.collectionpage .leading-5 a {
  text-decoration: underline;
}

/* EditorJS overrides */

.ce-block__content {
  margin: 0;
}

.prose ol > li.cdx-list__item::before,
.prose ul > li.cdx-list__item::before {
  content: none;
}

.prose .cdx-list {
  margin: 0;
  padding-left: 26px;
}

.prose li.cdx-list__item {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.4em 6px;
}

.prose .ce-header {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
}

#editorjs-minimal .ce-toolbar__plus,
#editorjs-minimal .ce-toolbar__actions-buttons {
  display: none;
}

.form-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  appearance: none;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #d2d6dc;
  border-radius: 0.375rem;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.tooltip {
  visibility: hidden;
  position: absolute;
}

:root {
  /* defaut color if platformColors are not set */
  /* the values of these vars are overwritten on platform data load */
  --platform-color-hero-text: #333333;
  --platform-color-primary: #0000ff;
  --platform-color-dark: #333333;
  --platform-color-light: #f3f3f3;
}

.platform-hero-text {
  color: var(--platform-color-hero-text);
}

.platform-hero-text::placeholder {
  color: var(--platform-color-hero-text);
  opacity: 0.7;
}

.platform-primary-text a {
  color: var(--platform-color-primary);
}

.bg-platform-primary {
  background-color: var(--platform-color-primary);
}

.bg-platform-dark {
  background-color: var(--platform-color-dark);
}

.platform-dark-color {
  color: var(--platform-color-dark);
}

.bg-platform-light-color {
  background-color: var(--platform-color-light);
}

.platform-light-color {
  color: var(--platform-color-light);
}

/* Add this to the end of the file */

/* Audio Player Styles */
.tribe-audio-player {
  @apply w-full p-5 bg-gray-100 rounded-lg;
  --primary-color: #1db954; /* Default color, will be overridden by inline style */
}

.tribe-audio-player .timecode {
  @apply text-center my-2.5 text-sm;
  color: var(--primary-color);
}

.tribe-audio-player .controls {
  @apply flex justify-center items-center mt-2.5;
}

.tribe-audio-player .side-control {
  @apply w-10 flex justify-center;
}

.tribe-audio-player .control-button {
  @apply border-none bg-transparent cursor-pointer flex items-center justify-center;
  color: var(--primary-color);
}

.tribe-audio-player .control-button:hover {
  opacity: 0.8;
}

.tribe-audio-player .play-pause-button {
  @apply mx-3.5;
}

.tribe-audio-player .loop-button {
  @apply text-gray-400;
}

.tribe-audio-player .loop-button.active {
  color: var(--primary-color);
}

.tribe-audio-player .playback-rate-button {
  @apply text-base font-bold w-10 text-center;
}

.tribe-audio-player .control-button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
